import React from "react";

import logoWhiteSVG from "../../../../../assets/logo/logo-white.svg"
import {logoPurpleSVG} from "../../utils/constants/shared-constants";

import "./logo.scss";


const Logo = ({className, color, imgClass, target, isBtn, href}) => {
    return (
        <div className={className}>
            {
                isBtn
                    ? (
                        <a href={href} target={target}>
                            <img src={color === 'white' ? logoWhiteSVG : logoPurpleSVG}
                                 className={imgClass} alt="Logo of Flexudy"
                                 width="150px" height="60px"
                            />
                        </a>
                    )

                    : (
                        <img src={color === 'white' ? logoWhiteSVG : logoPurpleSVG}
                             className={imgClass} alt="Logo of Flexudy"
                             width="150px" height="60px"
                        />
                    )
            }
        </div>
    );
}

export default React.memo(Logo);
