import {createSlice} from "@reduxjs/toolkit";

import {CERTIFICATE_INITIAL_STATE} from "../../state/storyDefaultState";


export const certificateSlice = createSlice({
    name: 'certificate',
    initialState: CERTIFICATE_INITIAL_STATE,
    reducers: {
        resetCertificateSlice: () => CERTIFICATE_INITIAL_STATE,
        updateCandidateFullName: (state, action) => {
            state.fullName = action.payload;
        },
        updateCandidateEmailAddress: (state, action) => {
            state.emailAddress = action.payload;
        },
        saveCandidateExamStatus: (state, action) => {
            state.isSuccess = action.payload;
        },
        saveCandidatePercentage: (state, action) => {
            state.percentage = action.payload;
        },
        saveCertificateId: (state, action) => {
            state.certificateId = action.payload;
        },
    }
});


export const { resetCertificateSlice, updateCandidateFullName, updateCandidateEmailAddress, saveCandidateExamStatus,
    saveCandidatePercentage, saveCertificateId } = certificateSlice.actions;

export const selectCandidateFullName = (state) => state.certificate.fullName;
export const selectCandidateEmailAddress = (state) => state.certificate.emailAddress;
export const selectCandidateExamStatus = (state) => state.certificate.isSuccess;
export const selectCandidatePercentage = (state) => state.certificate.percentage;
export const selectCertificateId = (state) => state.certificate.certificateId;

export default certificateSlice.reducer;
