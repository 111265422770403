import { createSlice } from "@reduxjs/toolkit";

export const generationSlice = createSlice({
    name: "generation",
    initialState: {
        questionSuggestion: '',
        answerSuggestion: '',
        isGenerateLoading: false,
        generatedQuestions: [],
        generatedOptions: [],
        summaryAlert: {
            show: false,
            message: {
                status: null,
                text: null,
                type: "info",
            },
        },
        summaryPhase: '',
        mcQuestionAlert: {
            show: false,
            message: {
                status: null,
                text: null,
                type: "info",
            },
        },
    },
    reducers: {
        updateQuestionSuggestion: (state, action) => {
            state.questionSuggestion = action.payload;
        },
        updateAnswerSuggestion: (state, action) => {
            state.answerSuggestion = action.payload;
        },
        updateIsGenerateLoading: (state, action) => {
            state.isGenerateLoading = action.payload;
        },
        saveGeneratedQuestion: (state, action) => {
            state.generatedQuestions = action.payload;
        },
        saveGeneratedOption: (state, action) => {
            state.generatedOptions = action.payload;
        },
        updateSummaryAlert: (state, action) => {
            state.summaryAlert = action.payload;
        },
        updateMCQuestionAlert: (state, action) => {
            state.mcQuestionAlert = action.payload;
        },
        updateSummarizeStatus: (state, action) => {
            state.summaryPhase = action.payload;
        },
        resetSummaryAlert: (state, action) => {
            state.summaryAlert = {
                show: false,
                message: {
                    status: null,
                    text: null,
                    type: "info",
                },
            };
        },
        resetMCQuestionAlert: (state, action) => {
            state.mcQuestionAlert = {
                show: false,
                message: {
                    status: null,
                    text: null,
                    type: "info",
                },
            };
        },
    },
});


export const { updateQuestionSuggestion, updateAnswerSuggestion, updateSummaryAlert, resetSummaryAlert,
    updateIsGenerateLoading, saveGeneratedQuestion, saveGeneratedOption, updateSummarizeStatus,
    updateMCQuestionAlert, resetMCQuestionAlert} = generationSlice.actions;


export const selectQuestionSuggestion = (state) => state.generation.questionSuggestion;
export const selectAnswerSuggestion = (state) => state.generation.answerSuggestion;

export const selectGeneratedQuestions = (state) => state.generation.generatedQuestions;
export const selectGeneratedOptions = (state) => state.generation.generatedOptions;

export const selectSummaryAlert = (state) => state.generation.summaryAlert;
export const selectSummarizeStatus = (state) => state.generation.summaryPhase;
export const selectMCQuestionAlert = (state) => state.generation.mcQuestionAlert;


export default generationSlice.reducer;
