
import {isEmpty} from "lodash";

import {KEY_LOCAL_CERTIFICATION_GUIDE, KEY_LOCAL_EDITOR_GUIDE, KEY_LOCAL_STORAGE_GUEST_ID,
  KEY_LOCAL_STORAGE_INTRO_VIDEO, KEY_LOCAL_STORAGE_USER, KEY_LOCAL_TRAINING_GUIDE, KEY_LOCAL_USER_LANGUAGE
} from "../../pages/shared/utils/constants/service-constants";


export const isLocalRun = () =>
  window.location.hostname === "localhost" ||
  window.location.hostname === "127.0.0.1" ||
  window.location.hostname === "";

export const isNotAuthenticatedUser = () => !getCurrentLoggedUser();

export const showDemoFeatures = (isCurrentlyDemoUser) => {
  return isNotAuthenticatedUser() || isCurrentlyDemoUser;
};

export const getUserLanguage = () => {
  const navigatorLanguage = navigator.language.split(/[-_]/)[0];
  const savedLanguage = localStorage.getItem(KEY_LOCAL_USER_LANGUAGE);
  return isEmpty(savedLanguage) ? navigatorLanguage : savedLanguage;
}

export const setUserLanguage = (language) => {
  return localStorage.setItem(KEY_LOCAL_USER_LANGUAGE, language);
}

export const getCurrentLoggedUser = () => {
  return JSON.parse(localStorage.getItem(KEY_LOCAL_STORAGE_USER));
};

export const getCurrentGuestUser = () => {
  return localStorage.getItem(KEY_LOCAL_STORAGE_GUEST_ID);
};

export const storeUserInfo = (userInfo) => {
  localStorage.setItem(KEY_LOCAL_STORAGE_USER, userInfo);
};

export const storeIntroVideoStatus = (viewStatus) => {
  localStorage.setItem(KEY_LOCAL_STORAGE_INTRO_VIDEO, viewStatus);
};

export const getIntroVideoStatus = () => {
  return localStorage.getItem(KEY_LOCAL_STORAGE_INTRO_VIDEO);
};

export const storeEditorGuideStatus = (viewStatus) => {
  localStorage.setItem(KEY_LOCAL_EDITOR_GUIDE, viewStatus);
};

export const getEditorGuideStatus = () => {
  return localStorage.getItem(KEY_LOCAL_EDITOR_GUIDE);
};

export const storeTrainingGuideStatus = (viewStatus) => {
  localStorage.setItem(KEY_LOCAL_TRAINING_GUIDE, viewStatus);
};

export const getTrainingGuideStatus = () => {
  return localStorage.getItem(KEY_LOCAL_TRAINING_GUIDE);
};

export const storeCertificationGuideStatus = (viewStatus) => {
  localStorage.setItem(KEY_LOCAL_CERTIFICATION_GUIDE, viewStatus);
};

export const getCertificationGuideStatus = () => {
  return localStorage.getItem(KEY_LOCAL_CERTIFICATION_GUIDE);
};

export const storeGuestId = (guestId) => {
  localStorage.setItem(KEY_LOCAL_STORAGE_GUEST_ID, guestId);
};

export const logout = () => {
  localStorage.removeItem(KEY_LOCAL_STORAGE_USER);
  localStorage.removeItem(KEY_LOCAL_STORAGE_GUEST_ID);
};
