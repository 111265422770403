
export const IMAGE_MEDIA_TYPE_FILE = 'IMAGE_FILE';
export const IMAGE_MEDIA_TYPE_GIF = 'IMAGE_GIF';
export const IMAGE_MEDIA_TYPE_CAMERA= 'IMAGE_CAMERA';

export const IMAGE_POSITION_FULL = 'FULL_CARD';
export const IMAGE_POSITION_BOTTOM = 'BOTTOM_CARD';
export const IMAGE_POSITION_TOP = 'TOP_CARD';
export const IMAGE_POSITION_CIRCLED = 'CIRCLED_CENTER_CARD';

export const IMAGE_FIT_FILL = 'fill';
export const IMAGE_FIT_CONTAIN = 'contain';
export const IMAGE_FIT_COVER = 'cover';

export const IMAGE_GIF_INITIAL_KEYWORDS = 'cars';
export const IMAGE_FILE_LIMIT_MB = 25 * 1024 * 1024;
export const IMAGE_FILE_TYPES = ["image/jpeg", "image/png", "image/gif"];
export const IMAGE_FILE_TYPE_PNG = "image/png";
export const IMAGE_FILE_EXTENSIONS = ["jpeg", "png", "gif"];
export const IMAGE_FILE_EXTENSION_GIF = 'gif';


export const AUDIO_MEDIA_TYPE_FILE = 'AUDIO_FILE';
export const AUDIO_MEDIA_TYPE_RECORDING = 'AUDIO_RECORDING';
export const AUDIO_MEDIA_TYPE_LINK = 'AUDIO_LINK';
export const AUDIO_MEDIA_TYPE_TEXT = 'AUDIO_TEXT';

export const AUDIO_FILE_SIZE_LIMIT_MB = 25 * 1024 * 1024;
export const AUDIO_FILE_TIME_LIMIT = 3 * 60;

//export const AUDIO_FILE_TYPES = ["audio/mid", "audio/mid", "audio/mpeg", "audio/x-m4a"];
export const AUDIO_FILE_TYPES = ["audio/mpeg"];
export const AUDIO_FILE_TYPE_MP3 = "audio/mpeg";
export const AUDIO_FILE_TYPE_WEBM = "audio/webm";
export const AUDIO_FILE_EXTENSIONS = ["mid", "rmi", "mp3", "mp4", "m4a",];
export const AUDIO_FILE_EXTENSION_MP3 = 'mp3';


export const VIDEO_MEDIA_TYPE_FILE = 'VIDEO_FILE';
export const VIDEO_MEDIA_TYPE_YOUTUBE = 'VIDEO_YOUTUBE';
export const VIDEO_MEDIA_TYPE_SYNTHESIA = 'VIDEO_SYNTHESIA';
export const VIDEO_MEDIA_TYPE_VIMEO = 'VIDEO_VIMEO';
export const VIDEO_MEDIA_TYPE_LINK = 'VIDEO_LINK';
export const VIDEO_MEDIA_TYPE_RECORDING = 'VIDEO_RECORDING';
export const VIDEO_MEDIA_TYPE_TEXT = 'VIDEO_TEXT';

export const VIDEO_POSITION_FULL = 'FULL_CARD';
export const VIDEO_POSITION_DEFAULT = 'DEFAULT_CARD';

export const VIDEO_FILE_LIMIT_MB = 25 * 1024 * 1024;
export const VIDEO_FILE_TIME_LIMIT = 3 * 60;


//export const VIDEO_FILE_TYPES = ["video/mp4", "video/webm"];
export const VIDEO_FILE_TYPES = ["video/mp4"];
export const VIDEO_FILE_TYPE_MP4 = "video/mp4";
export const VIDEO_FILE_TYPE_WEBM = "video/webm";
export const VIDEO_FILE_EXTENSIONS = ["mp4", "webm", "m3u8"];
