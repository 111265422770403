import {createSlice} from "@reduxjs/toolkit";

export const loadingSlice = createSlice({
    name: 'loading',
    initialState: {
        isSpinnerLoading: false,
        isSaveLoading: false,
        isProcessLoading: false,
    },

    reducers: {
        updateIsSpinnerLoading: (state, action) => {
            state.isSpinnerLoading = action.payload;
        },
        updateIsSaveLoading: (state, action) => {
            state.isSaveLoading = action.payload;
        },
        updateIsProcessLoading: (state, action) => {
            state.isProcessLoading = action.payload;
        }
    }
});


export const { updateIsSpinnerLoading, updateIsSaveLoading, updateIsProcessLoading } = loadingSlice.actions;

export const selectIsSpinnerLoading = (state) => state.loading.isSpinnerLoading;
export const selectIsSaveLoading = (state) => state.loading.isSaveLoading;
export const selectIsProcessLoading = (state) => state.loading.isProcessLoading;



export default loadingSlice.reducer;
