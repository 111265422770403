import { createSlice } from "@reduxjs/toolkit";

export const contentSlice = createSlice({
    name: "content",
    initialState: {
        contentChecksum: null,
        file: {
            locationUrl: null,
            fileName: null,
            fileExtension: null,
        },
        textContent: "",
        isTextContentURL: false,
    },
    reducers: {
        saveContentChecksum: (state, action) => {
            state.contentChecksum = action.payload;
        },
        saveFile: (state, action) => {
            state.file = action.payload;
        },
        saveTextContent: (state, action) => {
            state.textContent = action.payload;
        },
        updateURLValidation: (state, action) => {
            state.isTextContentURL = action.payload;
        },
    },
});

export const { saveFile, saveContentChecksum, saveTextContent, updateURLValidation } = contentSlice.actions;


export const selectContentChecksum = (state) => state.content.contentChecksum;

export const selectFileLocationUrl = (state) => state.content.file.locationUrl;
export const selectFileName = (state) => state.content.file.fileName;
export const selectFileExtension = (state) => state.content.file.fileExtension;

export const selectTextContent = (state) => state.content.textContent;
export const selectIsTextContentUrl = (state) => state.content.isTextContentURL;

export default contentSlice.reducer;
