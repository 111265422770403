import { createSlice } from "@reduxjs/toolkit";

import {USER_INITIAL_STATE} from "../../state/userDefaultState";


export const userSlice = createSlice({
  name: "user",
  initialState: USER_INITIAL_STATE,
  reducers: {
    resetUserSlice: () => USER_INITIAL_STATE,
    saveUserConfiguration: (state, action) => {
      state.authInfos = {
        name: action.payload.name,
        emailAddress: action.payload.emailAddress,
        licenseKey: action.payload.licenseKey,
        token: action.payload.token,
        loginStatus: true,
        flexudyAdmin: action.payload.flexudyAdmin,
        tenants: action.payload.tenants,
        defaultModule: action.payload.defaultModule,
      };
      state.subscriptionInfos = {
        subscription: action.payload?.subscription,
        whitelistedUser: action.payload?.whitelistedUser,
        currentlyOnDemoPlan: action.payload?.currentlyOnDemoPlan
      };
      state.mediaSize = {
        fileStorage: {
          usedSizeInMB: action.payload.usedSizeInMB,
          maximumAllowedSizeInMB: action.payload.maximumAllowedSizeInMB,
        },
        aiGeneration: {
          currentMonthlyAIGenerations: action.payload.currentMonthlyAIGenerations,
          maxMonthlyAIGenerations: action.payload.maxMonthlyAIGenerations,
        }
      };
    },

    updateUserEmail: (state, action) => {
      state.authInfos.emailAddress = action.payload;
    },
    updateUserName: (state, action) => {
      state.authInfos.name = action.payload;
    },

    saveSubscription: (state, action) => {
      state.subscriptionInfos = {
        subscription: action.payload?.subscription,
        whitelistedUser: action.payload?.whitelistedUser,
      };
    },

    saveCounters: (state, action) => {
      state.counters = action.payload?.quotas;
      state.features = action.payload.features;
    },

    updateLogin: (state, action) => {
      state.authInfos.loginStatus = action.payload;
    },

    updateAnonymousStoryUserStatus: (state, action) => {
      state.story.isAnonymous = action.payload;
    },
  },
});


export const { resetUserSlice, saveUserConfiguration, updateUserEmail, updateUserName, saveSubscription,
  saveCounters, updateLogin, updateAnonymousStoryUserStatus} = userSlice.actions;


export const selectUserToken = (state) => state.user.authInfos.token;
export const selectUserLicenseKey = (state) => state.user.authInfos.licenseKey;
export const selectUserEmail = (state) => state.user.authInfos.emailAddress;
export const selectLoginStatus = (state) => state.user.authInfos.loginStatus;
export const selectUserFlexudyAdminStatus = (state) => state.user.authInfos.flexudyAdmin;
export const selectUserTenants = (state) => state.user.authInfos.tenants;
export const selectUserDefaultModule = (state) => state.user.authInfos.defaultModule;

export const selectUserSubscription = (state) => state.user.subscriptionInfos.subscription;
export const selectUserWhitelistedStatus = (state) => state.user.subscriptionInfos.whitelistedUser;
export const selectUserCurrentlyOnDemoPLan = (state) => state.user.subscriptionInfos.currentlyOnDemoPlan;
export const selectMaxNumberOfCharacters = (state) => state.user.features.maxNumberOfCharacters;

export const selectMediaSizeAIGeneration = (state) => state.user.mediaSize.aiGeneration;
export const selectMediaSizeFileStorage = (state) => state.user.mediaSize.fileStorage;

export const selectStoryUserStatus = (state) => state.user.story.isAnonymous;


export default userSlice.reducer;
