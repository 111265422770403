import checksum from "checksum";
import isURL from 'validator/lib/isURL'
import {v4 as uuidv4} from 'uuid';

import {IMAGE_FILE_TYPES} from "../constants/media-constants";


String.prototype.replaceAt = (index, replacement) => {
    return this.substring(0, index) + replacement + this.substring(index + replacement.length);
}


export const createBodyFormData = (content, file, params = {}) => {
    const bodyFormData = new FormData();
    if (content) {
        if (isValidUrl(content)) {
            bodyFormData.append("urls", content);
        } else {
            bodyFormData.append("textContent", content);
        }
    }
    if (file) {
        bodyFormData.append(
            "contentType",
            IMAGE_FILE_TYPES.includes(file.type) ? "IMAGE" : "BOOK"
        );
        bodyFormData.append("files", file);
    }
    if (params) {
        Object.keys(params).forEach((key) => bodyFormData.append(key, params[key]));
    }
    return bodyFormData;
};


export const isValidUrl = (string) => {
    return isURL(string);
};


export const computeChecksum = (data) => {
    return checksum(data);
};


export const generateId = () => {
    return uuidv4();
}


export const escapeRegExp = (text) => {
    const alpha_num_chars = new Set("_abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ01234567890");

    let pattern = text.split("");
    let final_pattern = "";

    for(let index = 0; index < pattern.length; index++){
        if(!alpha_num_chars.has(pattern[index])) {

            if(pattern[index] === '\0') {
                final_pattern += '\\000';
            } else{
                final_pattern += "\\" + pattern[index];
            }
        }
        else{
            final_pattern += pattern[index];
        }
    }

    return final_pattern;
}

export const hexToRgb = (hex) => {
    if(hex) {
        const rgb = hex.replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i,(m, r, g, b) => '#' + r + r + g + g + b + b)
            .substring(1).match(/.{2}/g)
            .map(x => parseInt(x, 16))
        return {r: rgb[0], g: rgb[1], b: rgb[2]}
    }
}

/**
 * https://www.sitepoint.com/get-url-parameters-with-javascript/
 * @param url
 * @returns {{}}
 */
export const getAllUrlParams = (url) => {

    // get query string from url (optional) or window
    let queryString = url ? url.split('?')[1] : window.location.search.slice(1);

    // we'll store the parameters here
    const obj = {};

    // if query string exists
    if (queryString) {

        // stuff after # is not part of query string, so get rid of it
        queryString = queryString.split('#')[0];

        // split our query string into its component parts
        const arr = queryString.split('&');

        for (let i = 0; i < arr.length; i++) {
            // separate the keys and the values
            const a = arr[i].split('=');

            // set parameter name and value (use 'true' if empty)
            let paramName = a[0];
            let paramValue = typeof (a[1]) === 'undefined' ? true : a[1];

            // (optional) keep case consistent
            paramName = paramName.toLowerCase();
            if (typeof paramValue === 'string') paramValue = paramValue.toLowerCase();

            // if the paramName ends with square brackets, e.g. colors[] or colors[2]
            if (paramName.match(/\[(\d+)?\]$/)) {

                // create key if it doesn't exist
                const key = paramName.replace(/\[(\d+)?\]/, '');
                if (!obj[key]) obj[key] = [];

                // if it's an indexed array e.g. colors[2]
                if (paramName.match(/\[\d+\]$/)) {
                    // get the index value and add the entry at the appropriate position
                    const index = /\[(\d+)\]/.exec(paramName)[1];
                    obj[key][index] = paramValue;
                } else {
                    // otherwise add the value to the end of the array
                    obj[key].push(paramValue);
                }
            } else {
                // we're dealing with a string
                if (!obj[paramName]) {
                    // if it doesn't exist, create property
                    obj[paramName] = paramValue;
                } else if (obj[paramName] && typeof obj[paramName] === 'string'){
                    // if property does exist and it's a string, convert it to an array
                    obj[paramName] = [obj[paramName]];
                    obj[paramName].push(paramValue);
                } else {
                    // otherwise add the property
                    obj[paramName].push(paramValue);
                }
            }
        }
    }

    return obj;
}
