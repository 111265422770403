import {COVER_DEFAULT_THEME} from "../../pages/shared/utils/constants/theme-constants";
import {
    DEFAULT_QUIZ_TIME_SECONDS,
    PERCENTAGE_MINIMUM_FOR_CERTIFICATION, QUIZ_LEVEL_1,
    REGULAR_POINT_SYSTEM
} from "../../pages/shared/utils/constants/shared-constants";


export const CONFIG_INITIAL_STATE = {
    info: {
        workspaceId: '',
        defaultWorkspace: true,
        moduleId: '',
        nuggetId: '',
        name: '',
        institutionName: '',
        theme: COVER_DEFAULT_THEME
    },
    timer: {
        isTimerAvailable: false,
        isTimeEnd: false,
        timeInSeconds: DEFAULT_QUIZ_TIME_SECONDS,
    },
    graduation: {
        maxScore: 0,
        notationType: REGULAR_POINT_SYSTEM,
    },
    exam: {
        certifiable: false,
        description: '',
        certificateMinimumPercent: PERCENTAGE_MINIMUM_FOR_CERTIFICATION,
        difficultyLevel: QUIZ_LEVEL_1,
    },
    details: {
        shared: false,
        viewCount: 0,
        socialMediaFeatures: false,
        averageScore: 0,
        password: ''
    },
};
