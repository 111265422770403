import React from "react";
import {Navigate} from "react-router-dom";


const OpenRoute = ({isAuthenticated, children}) => {

    if(isAuthenticated) {
        return <Navigate to="/" replace={true} />;
    }


    return children;
}

export default React.memo(OpenRoute)
