import React from "react";
import {Navigate, useNavigate} from "react-router-dom";

import {useQuery} from "../../pages/shared/hooks/router-hook";
import {getDocOnlyWithId} from "../../service/data/storyService";


const RedirectRoute = () => {

    const queryParams = useQuery();
    const navigate = useNavigate()

    const flexudyId = queryParams.get("flexudyId");

    const moduleId = queryParams.get("mId");
    const nuggetId = queryParams.get("dId");


    if(moduleId && nuggetId) {
        return <Navigate to={`/workspaces/private/mods/${moduleId}/nuggets/${nuggetId}/story`} replace={true} />;
    }


    if(flexudyId) {
        getDocOnlyWithId({defaultWorkspace: true}, flexudyId)
            .then((serverResponse) => {
                const jsonResponse = serverResponse.data;
                const receivedModuleId = jsonResponse.moduleId;
                return navigate(`/workspaces/private/mods/${receivedModuleId}/nuggets/${flexudyId}/story`, {replace: true});
            })
            .catch(() => {
                console.log('error')
            })
    }

    return <Navigate to="/workspaces" replace={true} />;
}

export default React.memo(RedirectRoute);
