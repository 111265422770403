import { createSlice } from "@reduxjs/toolkit";

export const saveSlice = createSlice({
  name: "save",
  initialState: {
    shouldCreateDoc: false,
    shouldUpdateDoc: false,
    createInfos: {
      shouldCreateCard: false,
      currentCardIndex: null
    },
    updateInfos: {
      shouldUpdateCard: false,
      currentCardIndex: null
    },
    shouldMoveLeftAndUpdateCard: null,
    shouldMoveRightAndUpdateCard: null,
    showSaveConfirmation: false,
  },
  reducers: {
    saveCreateDocStatus: (state, action) => {
      state.shouldCreateDoc = action.payload;
    },
    saveUpdateDocStatus: (state, action) => {
      state.shouldUpdateDoc = action.payload;
    },
    saveCreateCardStatus: (state, action) => {
      state.createInfos = action.payload;
    },
    saveUpdateCardStatus: (state, action) => {
      state.updateInfos = action.payload;
    },
    saveUpdateMoveLeftCardStatus: (state, action) => {
      state.shouldMoveLeftAndUpdateCard = action.payload;
    },
    saveUpdateMoveRightCardStatus: (state, action) => {
      state.shouldMoveRightAndUpdateCard = action.payload;
    },
    updateSaveFeedbackStatus: (state, action) => {
      state.showSaveConfirmation = action.payload;
    },
  },
});

export const {saveCreateDocStatus, saveUpdateDocStatus, saveCreateCardStatus, saveUpdateCardStatus,
  updateSaveFeedbackStatus, saveUpdateMoveLeftCardStatus, saveUpdateMoveRightCardStatus} = saveSlice.actions;


export const selectShouldCreateDoc = (state) => state.save.shouldCreateDoc;
export const selectShouldUpdateDoc = (state) => state.save.shouldUpdateDoc;

export const selectCreateInfos = (state) => state.save.createInfos;
export const selectUpdateInfos = (state) => state.save.updateInfos;

export const selectShouldMoveLeftAndUpdateCard = (state) => state.save.shouldMoveLeftAndUpdateCard;
export const selectShouldMoveRightAndUpdateCard = (state) => state.save.shouldMoveRightAndUpdateCard;

export const selectShowSaveConfirmation = (state) => state.save.showSaveConfirmation;

export default saveSlice.reducer;
