import { createSlice } from "@reduxjs/toolkit";

import {DOCUMENT_INITIAL_STATE} from "../../state/homeDefaultState";


export const documentSlice = createSlice({
    name: "document",
    initialState: DOCUMENT_INITIAL_STATE,
    reducers: {
        resetDocumentSlice: () => DOCUMENT_INITIAL_STATE,
        saveDocuments: (state, action) => {
            state.allDocs = action.payload;
        },
        addNewDoc: (state, action) => {
            state.allDocs.push(action.payload);
        },
        removeDoc: (state, action) => {
            state.allDocs = state.allDocs.filter((entry) => entry.id !== action.payload);
        },
        updateIsQuizLoading: (state, action) => {
            state.isQuizLoading = action.payload;
        },
        updateDocItem: (state, action) => {
            state.allDocs[action.payload.index] = action.payload.item;
        },
        updateCardView: (state, action) => {
            state.cardView = action.payload;
        },
        saveActiveDocument: (state, action) => {
            state.activeDocument = action.payload;
        },
        saveShouldRefresh: (state, action) => {
            state.shouldRefresh = action.payload;
        },
        saveStatsViewInfos: (state, action) => {
            state.statsViewInfos = action.payload;
        },
        saveStatsViewInfosRefresh: (state, action) => {
            state.statsViewInfos.refreshStats = action.payload;
        },
        saveStatsViewInfosLoading: (state, action) => {
            state.statsViewInfos.loadStats = action.payload;
        },
        saveStatsViewInfosError: (state, action) => {
            state.statsViewInfos.isError = action.payload;
        },
    },
});


export const {resetDocumentSlice, saveDocuments, removeDoc, addNewDoc, updateIsQuizLoading,
    updateDocItem, updateCardView, saveActiveDocument, saveShouldRefresh, saveStatsViewInfos,
    saveStatsViewInfosRefresh, saveStatsViewInfosLoading, saveStatsViewInfosError} = documentSlice.actions;


export const selectAllDocuments = (state) => state.document.allDocs;
export const selectSizeOfAllDocuments = (state) => state.document.allDocs.length;
export const selectNuggetsCardView = (state) => state.document.cardView;
export const selectActiveNugget = (state) => state.document.activeDocument;
export const selectShouldRefresh = (state) => state.document.shouldRefresh;
export const selectStatsViewInfos = (state) => state.document.statsViewInfos;

export default documentSlice.reducer;
