import httpClient from "../api";
import { storeUserInfo } from "./sessionService";
import { User } from "../../pages/login/utils/models/User";


export const fetchUserInfo = async () => {
    const url = "/api/v1/b2b/users/current-user";
    return httpClient.get(url);
};


export const updateUserDetails = (jsonBody) => {
    const url = `api/v1/b2b/users/details`;
    return httpClient.put(url, jsonBody);
}


export const fetchMonthlyCounters = async () => {
    const url = "/api/v1/b2b/users/current-user/monthly-counters";
    return httpClient.get(url);
};


export const fetchEmailAvailability = async (emailAddress) => {
    const url = `/api/v1/b2b/users/sign-up/email-address-available`;
    return httpClient.post(url, { emailAddress });
};


export const createAccount = async (name, emailAddress, password) => {
    const url = "/api/v1/b2b/users/sign-up";

    const httpJsonBody = {
        name,
        emailAddress,
        password,
    };

    return httpClient
        .post(url, httpJsonBody)
        .then((serverResponse) => {
            const headers = serverResponse.headers;
            const currentUser = new User("", headers.authorization);
            storeUserInfo(JSON.stringify(currentUser));
        })
        .catch((e) => {
            throw Error("Failed to logged in!" + e);
        });
};


export const sendUserFeedback = (score, comment) => {
    const url = `api/internal/nps`;
    const jsonBody = {score, comment};
    return httpClient.post(url, jsonBody);
}
