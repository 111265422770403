
export const STORY_INITIAL_STATE = {
    storyArray: [],
    storyType: '',
    lastSuggestions: [],
    shouldCheckNext: false,
    shouldSaveCardProgression: null,
};

export const CERTIFICATE_INITIAL_STATE = {
    fullName: '',
    emailAddress: '',
    percentage: 0,
    isSuccess: false,
    certificateId: '',
};
