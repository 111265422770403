import { createSlice } from "@reduxjs/toolkit";

import {ADMIN_INITIAL_STATE} from "../../state/adminDefaultState";


export const adminSlice = createSlice({
    name: "admin",
    initialState: ADMIN_INITIAL_STATE,
    reducers: {
        resetAdminSlice: () => ADMIN_INITIAL_STATE,
        saveTenants: (state, action) => {
            state.allTenants = action.payload;
        },
        saveActiveTenant: (state, action) => {
            state.activeTenant = action.payload;
        },
        updateAdminCardView: (state, action) => {
            state.cardView = action.payload;
        },
        saveRefreshStatus: (state, action) => {
            state.shouldRefresh = action.payload;
        },
    },
});

export const { resetAdminSlice, saveTenants, saveActiveTenant,
    updateAdminCardView, saveRefreshStatus } = adminSlice.actions;


export const selectAllTenants = (state) => state.admin.allTenants;
export const selectSizeOfAllTenants = (state) => state.admin.allTenants.length;
export const selectActiveTenant = (state) => state.admin.activeTenant;
export const selectAdminCardView = (state) => state.admin.cardView;
export const selectRefreshStatus = (state) => state.admin.shouldRefresh;


export default adminSlice.reducer;
