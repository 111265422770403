import httpClient from "../api";
import {buildLikeCardURL, buildViewCardURL} from "../../pages/story/utils/functions/api-url-builder";


export const saveViewDocInModule = (activeWorkspace, moduleId, nuggetId) => {
    const url = `api/internal/modules/${moduleId}/quizzes/${nuggetId}/views`;

    if(!activeWorkspace.defaultWorkspace) {
        return httpClient.post(url, {}, {headers: {'tenantId': activeWorkspace.id}});
    }

    return httpClient.post(url);
}


export const getDocOnlyWithId = (activeWorkspace, nuggetId) => {
    const url = `api/internal/quizzes/${nuggetId}`;

    if(!activeWorkspace.defaultWorkspace) {
        return httpClient.get(url, {headers: {'tenantId': activeWorkspace.id}});
    }

    return httpClient.get(url);
}


export const getDocInModule = (activeWorkspace, moduleId, nuggetId, password) => {
    const url = `api/internal/modules/${moduleId}/quizzes/${nuggetId}`;
    const jsonBody = {password};

    if(!activeWorkspace.defaultWorkspace) {
        return httpClient.post(url, jsonBody, {headers: {'tenantId': activeWorkspace.id}});
    }

    return httpClient.post(url, jsonBody);
}


export const checkIfDocInModuleIsProtected = async (activeWorkspace, moduleId, nuggetId) => {
    const url = `api/internal/modules/${moduleId}/quizzes/${nuggetId}/password-status`;

    if(!activeWorkspace.defaultWorkspace) {
        return await httpClient.get(url, {headers: {'tenantId': activeWorkspace.id}});
    }

    return await httpClient.get(url);
}


/*
export const getStatsWithId = (contentId) => {
    const url = process.env.REACT_APP_GATEWAY_API_URL + `api/internal/quizzes/${contentId}/share/stats`;

    return fetch(url, {method: 'GET', headers: AUTH_HEADERS_STORY})
        .then((serverResponse) => {
            return serverResponse.json();
        })
        .catch((error) => {
        })
}
 */


export const saveResultOfDocInModule = (nuggetsInfos, scoreResult, password) => {
    const url = `api/internal/modules/${nuggetsInfos.moduleId}/quizzes/${nuggetsInfos.nuggetId}/share/stats`;
    const jsonBody = {
        score: scoreResult,
        password
    };

    if(!nuggetsInfos.defaultWorkspace) {
        return httpClient.post(url, jsonBody, {headers: {'tenantId': nuggetsInfos.workspaceId}});
    }

    return httpClient.post(url, jsonBody);
}


export const verifyMCQuestionAnswer = (nuggetsInfos, cardId, jsonBody) => {
    const url = `api/internal/modules/${nuggetsInfos.moduleId}/quizzes/${nuggetsInfos.nuggetId}/questions/${cardId}/verifyAnswers`;

    if(!nuggetsInfos.defaultWorkspace) {
        return httpClient.post(url, jsonBody, {headers: {'tenantId': nuggetsInfos.workspaceId}});
    }

    return httpClient.post(url, jsonBody);
}


export const SubmitCertificateAnswers = (nuggetsInfos, questions, recipient, emailAddress, password) => {
    const url = `api/internal/modules/${nuggetsInfos.moduleId}/quizzes/${nuggetsInfos.nuggetId}/attemptCertification`;
    const jsonBody = {
        questions,
        recipient,
        emailAddress,
        password
    }

    if(!nuggetsInfos.defaultWorkspace) {
        return httpClient.post(url, jsonBody, {headers: {'tenantId': nuggetsInfos.workspaceId}});
    }

    return httpClient.post(url, jsonBody);
}


export const saveCardView = (nuggetsInfos, cardId, viewType) => {
    const url = buildViewCardURL(nuggetsInfos, cardId, viewType);

    if(!nuggetsInfos.defaultWorkspace) {
        return httpClient.post(url, {}, {headers: {'tenantId': nuggetsInfos.workspaceId}});
    }

    return httpClient.post(url);
}

export const saveCardLike = (nuggetsInfos, cardId, viewType) => {
    const url = buildLikeCardURL(nuggetsInfos, cardId, viewType);

    if(!nuggetsInfos.defaultWorkspace) {
        return httpClient.post(url, {}, {headers: {'tenantId': nuggetsInfos.workspaceId}});
    }

    return httpClient.post(url);
}


export const unLikeCard = (nuggetsInfos, cardId, viewType) => {
    const url = buildLikeCardURL(nuggetsInfos, cardId, viewType);

    if(!nuggetsInfos.defaultWorkspace) {
        return httpClient.delete(url, {headers: {'tenantId': nuggetsInfos.workspaceId}});
    }

    return httpClient.delete(url);
}


export const getWorkspaceTheme = (moduleId, nuggetId) => {
    const url = `api/internal/modules/${moduleId}/quizzes/${nuggetId}/look-and-feel`;
    return httpClient.get(url);
}
