import {createSlice} from "@reduxjs/toolkit";

import {WORKSPACE_INITIAL_STATE} from "../../state/homeDefaultState";


export const workspaceSlice = createSlice({
    name: "workspace",
    initialState: WORKSPACE_INITIAL_STATE,
    reducers: {
        resetWorkspaceSlice: () => WORKSPACE_INITIAL_STATE,
        saveAllWorkspaces: (state, action) => {
            state.allWorkspaces = action.payload;
        },
        saveActiveWorkspace: (state, action) => {
            state.activeWorkspace = action.payload;
        },
        saveDefaultWorkspace: (state, action) => {
            state.defaultWorkspace = action.payload;
        },
        saveEditableWorkspace: (state, action) => {
            state.editableWorkspace = action.payload;
        },
        updateWorkspaceCardView: (state, action) => {
            state.cardView = action.payload;
        },

        updateWorkspacePrimaryColor: (state, action) => {
            state.editableWorkspace.primaryColor = action.payload;
        },
        updateWorkspaceSecondaryColor: (state, action) => {
            state.editableWorkspace.secondaryColor = action.payload;
        },
        updateWorkspaceBackgroundType: (state, action) => {
            state.editableWorkspace.background.type = action.payload;
        },
        updateWorkspaceBackgroundColorOption: (state, action) => {
            state.editableWorkspace.background.color = action.payload;
        },
        updateWorkspaceBackgroundImage: (state, action) => {
            state.editableWorkspace.background.link = action.payload;
            state.editableWorkspace.background.signedLink = false;
        },
        updateWorkspaceCompanyLogo: (state, action) => {
            state.editableWorkspace.logo.link = action.payload;
            state.editableWorkspace.logo.signedLink = false;
        },
        updateWorkspaceNuggetsReviewStatus: (state, action) => {
            state.editableWorkspace.nuggetsReview = action.payload;
        },
        updateWorkspaceNuggetsAccessStatus: (state, action) => {
            state.editableWorkspace.nuggetsLoginAccess = action.payload;
        },
    }
});


export const {resetWorkspaceSlice, saveAllWorkspaces, saveActiveWorkspace, saveDefaultWorkspace,
    updateWorkspaceCardView, saveEditableWorkspace, updateWorkspacePrimaryColor, updateWorkspaceBackgroundColorOption,
    updateWorkspaceBackgroundImage, updateWorkspaceNuggetsReviewStatus, updateWorkspaceBackgroundType,
    updateWorkspaceNuggetsAccessStatus, updateWorkspaceCompanyLogo,
    updateWorkspaceSecondaryColor} = workspaceSlice.actions;


export const selectAllWorkspaces = (state) => state.workspace.allWorkspaces;
export const selectActiveWorkspace = (state) => state.workspace.activeWorkspace;
export const selectDefaultWorkspace = (state) => state.workspace.defaultWorkspace;
export const selectEditableWorkspace = (state) => state.workspace.editableWorkspace;
export const selectWorkspaceCardView = (state) => state.workspace.cardView;

export const selectActiveWorkspacePalettePrimaryColor = (state) => state.workspace.activeWorkspace.theme.palette.primary;
export const selectActiveWorkspacePaletteSecondaryColor = (state) => state.workspace.activeWorkspace.theme.palette.secondary;
export const selectActiveWorkspaceBackgroundType = (state) => state.workspace.activeWorkspace.theme.background.type;
export const selectActiveWorkspaceBackgroundImage = (state) => state.workspace.activeWorkspace.theme.background.image;
export const selectActiveWorkspaceBackgroundColor = (state) => state.workspace.activeWorkspace.theme.background.color;
export const selectActiveWorkspaceCompanyLogo = (state) => state.workspace.activeWorkspace.theme.companyLogo;
export const selectActiveWorkspaceCustomCoverStatus = (state) => state.workspace.activeWorkspace.theme.customCover;
export const selectActiveWorkspaceCoverLogoStatus = (state) => state.workspace.activeWorkspace.theme.coverWithLogo;




export default workspaceSlice.reducer;
