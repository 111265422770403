import {createSlice} from "@reduxjs/toolkit";

import {EDITOR_INITIAL_STATE} from "../../state/editorDefaultState";
import {generateId} from "../../../pages/shared/utils/function/helper";


export const editorSlice = createSlice({
    name: 'editor',
    initialState: EDITOR_INITIAL_STATE,
    reducers: {
        resetEditorSlice: () => EDITOR_INITIAL_STATE,
        saveSlideArray: (state, action) => {
            state.slideArray = action.payload;
        },
        removeSlideItem: (state, action) => {
            state.slideArray = state.slideArray.filter((entry) => entry.id !== action.payload);
        },
        saveSlideItem: (state, action) => {
            state.savedSlide = state.slideArray[action.payload];
        },
        resetSlideItem: (state, action) => {
            state.slideArray[action.payload] = state.savedSlide;
            state.savedSlide = null;
        },
        removeSavedSlide: (state, action) => {
            state.savedSlide = null;
        },
        addSlideItem: (state, action) => {
            state.slideArray.splice(action.payload.index, 0, action.payload.item);
        },
        updateSlideItemErrorStatus: (state, action) => {
            state.slideArray[action.payload.slideIndex].isError = action.payload.error;
        },
        updateQuizItemQuestion: (state, action) => {
            state.slideArray[action.payload.index].question = action.payload.question;
        },
        updateQuizItemAnswer: (state, action) => {
            const slideIndex = action.payload.slideIndex;

            state.slideArray[slideIndex].question = action.payload.item.question;
            state.slideArray[slideIndex].context = action.payload.item.context;

            for(let index = 0; index < state.slideArray[slideIndex].distractors.length; index++) {
                const option = state.slideArray[slideIndex].distractors[index];
                if(option.answer) {
                    state.slideArray[slideIndex].distractors[index].distractor = action.payload.item.context;
                    break;
                }
            }
        },
        updateQuizItemOption: (state, action) => {
            const slideIndex = action.payload.slideIndex;
            const distractorIndex = action.payload.distractorIndex;
            state.slideArray[slideIndex].distractors[distractorIndex].distractor = action.payload.distractor;
        },
        addQuizItemOption: (state, action) => {
            const slideIndex = action.payload.slideIndex;
            const position = action.payload.optionPosition;
            state.slideArray[slideIndex].distractors.push(
                {id: generateId(), distractor: '', position: position, answer: false}
            )
        },
        removeQuizItemOption: (state, action) => {
            const slideIndex = action.payload.slideIndex;
            state.slideArray[slideIndex].distractors = state.slideArray[slideIndex].distractors.filter(
                (entry) => entry.id !== action.payload.optionId
            );
        },
        addQuizItemOptionToAnswer: (state, action) => {
            const answerIndex = action.payload.answerIndex;
            const slideIndex = action.payload.index;
            state.slideArray[slideIndex].distractors[answerIndex].answer = true;
        },
        removeQuizItemOptionFromAnswer: (state, action) => {
            const answerIndex = action.payload.answerIndex;
            const slideIndex = action.payload.index;
            state.slideArray[slideIndex].distractors[answerIndex].answer = false;
        },
        updateSlideItem: (state, action) => {
            const key = action.payload.key;
            const index = action.payload.slideIndex;
            state.slideArray[index][key] = action.payload.value;
        },
        switchToRight: (state, action) => {
            const slideIndex = action.payload;
            const tmp = {...state.slideArray[slideIndex]};
            state.slideArray[slideIndex] = {...state.slideArray[slideIndex + 1]};
            state.slideArray[slideIndex + 1] = tmp;

        },
        switchToLeft: (state, action) => {
            const slideIndex = action.payload;
            const tmp = {...state.slideArray[slideIndex]};
            state.slideArray[slideIndex] = {...state.slideArray[slideIndex - 1]};
            state.slideArray[slideIndex - 1] = tmp;
        },
        updateSwipeDirection: (state, action) => {
            state.swipeDirection = action.payload;
        },
        updateCoverTheme: (state, action) => {
            state.slideArray[0].theme = action.payload;
        },
    }
});


export const { resetEditorSlice, saveSlideArray, removeSlideItem, addSlideItem,
    updateSlideItemErrorStatus, updateQuizItemQuestion, updateQuizItemAnswer,
    updateQuizItemOption, addQuizItemOption, removeQuizItemOption, addQuizItemOptionToAnswer,
    removeQuizItemOptionFromAnswer, switchToRight, switchToLeft, updateSwipeDirection,
    updateCoverTheme, updateSlideItem, saveSlideItem, resetSlideItem, removeSavedSlide} = editorSlice.actions;


export const selectSlideArray = (state) => state.editor.slideArray;
export const selectSlideArraySize = (state) => state.editor.slideArray.length;
export const selectSavedSlide = (state) => state.editor.savedSlide;

export const selectSwipeDirection = state => state.editor.swipeDirection;



export default editorSlice.reducer;
