import { createSlice } from "@reduxjs/toolkit";

import {getEditorGuideStatus, getUserLanguage} from "../../../service/auth/sessionService";


export const uiSlice = createSlice({
  name: "ui",
  initialState: {
    alert: {
      show: false,
      message: {
        text: null,
        type: "info",
      },
    },
    updateUserInfo: false,
    showEditorGuide: getEditorGuideStatus(),
    showTrainingGuide: getEditorGuideStatus(),
    showCertificationGuide: getEditorGuideStatus(),
    showLogoutMessage: false,
    chosenLanguage: getUserLanguage(),
    showStoryLoginDialog: false,
    closeStoryLoginDialog: false,
    showNameDialog: false,
    imageViewer: {
      open: false,
      source: ''
    }
  },
  reducers: {
    showAccountModal: (state) => {
      state.showAccountModal = true;
    },
    hideAccountModal: (state) => {
      state.showAccountModal = false;
    },
    updateUserInfo: (state, action) => {
      state.updateUserInfo = action.payload.status;
    },
    displayAlert: (state, action) => {
      state.alert.message.type = action.payload.type || "success";
      state.alert.message.text = action.payload.message;
      state.alert.show = true;
    },
    closeAlert: (state) => {
      state.alert.message.type = "success";
      state.alert.message.text = null;
      state.alert.show = false;
    },
    updateEditorGuideStatus: (state, action) => {
      state.showEditorGuide = action.payload;
    },
    updateStoryTrainingGuideStatus: (state, action) => {
      state.showEditorGuide = action.payload;
    },
    updateStoryCertificationGuideStatus: (state, action) => {
      state.showEditorGuide = action.payload;
    },
    updateShowLogoutMessage: (state, action) => {
      state.showLogoutMessage = action.payload;
    },
    updateChosenLanguage: (state, action) => {
      state.chosenLanguage = action.payload;
    },
    updateShowStoryLoginDialog: (state, action) => {
      state.showStoryLoginDialog = action.payload;
    },
    updateCloseStoryLoginDialog: (state, action) => {
      state.closeStoryLoginDialog = action.payload;
    },
    updateShowNameDialog: (state, action) => {
      state.showNameDialog = action.payload;
    },
    updateImageViewerStatus: (state, action) => {
      state.imageViewer = action.payload;
    },
  },
});

export const {
  showAccountModal,
  hideAccountModal,
  displayAlert,
  closeAlert,
  updateUserInfo,
  updateAddDialogStatus,
  updateChosenLanguage,
  updateEditorGuideStatus,
  updateStoryTrainingGuideStatus,
  updateShowLogoutMessage,
  updateShowStoryLoginDialog,
  updateCloseStoryLoginDialog,
  updateShowNameDialog,
  updateImageViewerStatus
} = uiSlice.actions;

export const selectShouldUpdateUserInfo = (state) => state.ui.updateUserInfo;
export const selectDisplayAlertStatus = (state) => state.ui.alert.show;
export const selectAlertMessage = (state) => state.ui.alert.message;
export const selectShowEditorGuideStatus = (state) => state.ui.showEditorGuide;
export const selectShowLogoutMessage = (state) => state.ui.showLogoutMessage;
export const selectChosenLanguage = (state) => state.ui.chosenLanguage;
export const selectShowStoryLoginDialog = (state) => state.ui.showStoryLoginDialog;
export const selectCloseStoryLoginDialog = (state) => state.ui.closeStoryLoginDialog;
export const selectShowNameDialog = (state) => state.ui.showNameDialog;
export const selectImageViewerStatus = (state) => state.ui.imageViewer;

export default uiSlice.reducer;
