import {createSlice} from "@reduxjs/toolkit";

import {MODULE_INITIAL_STATE} from "../../state/homeDefaultState";


export const moduleSlice = createSlice({
    name: "module",
    initialState: MODULE_INITIAL_STATE,
    reducers: {
        resetModuleSlice: () => MODULE_INITIAL_STATE,
        saveAllModules: (state, action) => {
            state.allModules = action.payload;
        },
        saveDefaultModuleId: (state, action) => {
            state.defaultModuleId = action.payload;
        },
        savePaginationDetails: (state, action) => {
            state.totalNumberOfPages = action.payload.totalNumberOfPages;
            state.totalNumberOfModules = action.payload.totalNumberOfElements;
        },
        addNewModule: (state, action) => {
            state.allModules.splice(action.payload.index, 0, action.payload.module);
        },
        saveNewModule: (state, action) => {
            state.allModules[action.payload.index].id = action.payload.id;
            state.allModules[action.payload.index].isEditable = false;
        },
        removeModule: (state, action) => {
            state.allModules = state.allModules.filter((entry) => entry.id !== action.payload);
        },
        updateModuleItem: (state, action) => {
            const key = action.payload.key;
            const index = action.payload.index;
            state.allModules[index][key] = action.payload.value;
        },
        updateModuleItemEditStatus: (state, action) => {
            state.allModules[action.payload.index].isEditable = action.payload.isEditable;
        },
        updateModuleItemName: (state, action) => {
            state.allModules[action.payload.index].name = action.payload.name;
        },
        saveActiveModule: (state, action) => {
            state.activeModule = action.payload;
        },
    },
});


export const {resetModuleSlice, saveAllModules, removeModule, addNewModule, saveNewModule,
    updateModuleItemEditStatus, updateModuleItemName, saveActiveModule, savePaginationDetails,
    saveDefaultModuleId, updateModuleItem} = moduleSlice.actions;


export const selectAllModules = (state) => state.module.allModules;
export const selectActiveModule = (state) => state.module.activeModule;
export const selectDefaultModuleId = (state) => state.module.defaultModuleId;


export default moduleSlice.reducer;
