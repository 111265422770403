import {createSlice} from "@reduxjs/toolkit";

import {CONFIG_INITIAL_STATE} from "../../state/commonDefaultState";


export const configSlice = createSlice({
    name: 'config',
    initialState: CONFIG_INITIAL_STATE,
    reducers: {
        resetConfigSlice: () => CONFIG_INITIAL_STATE,
        saveConfiguration: (state, action) => {
            state.info = {
                workspaceId: action.payload.workspaceId,
                defaultWorkspace: action.payload.defaultWorkspace,
                moduleId: action.payload.moduleId,
                nuggetId: action.payload.nuggetId,
                name: action.payload.name,
                institutionName: action.payload.institutionName,
                theme: action.payload.theme,
            };
            state.timer = {
                isTimerAvailable: action.payload.timeInSeconds > 0,
                timeInSeconds: action.payload.timeInSeconds,
                isTimeEnd: false,
                counter: action.payload.timeInSeconds > 0 ? Number(action.payload.timeInSeconds) : 0,
            };
            state.graduation = {
                maxScore: action.payload.maxScore,
                notationType: action.payload.notationType,
            };
            state.exam = {
                certifiable: action.payload.certifiable,
                companyLogo: action.payload.companyLogo,
                description: action.payload.description,
                certificateMinimumPercent: action.payload.certificateMinimumPercent,
                difficultyLevel: action.payload.difficultyLevel,
            };
            state.details = {
                shared: action.payload.shared,
                viewCount: action.payload.viewCount,
                password: action.payload.password,
                socialMediaFeatures: action.payload.socialMediaFeatures,
                //averageScore: action.payload.averageScore,
            };
        },
        saveInfoConfiguration: (state, action) => {
            state.info = {
                workspaceId: action.payload.workspaceId,
                defaultWorkspace: action.payload.defaultWorkspace,
                moduleId: action.payload.moduleId,
                nuggetId: action.payload.nuggetId,
                name: action.payload.name,
                institutionName: action.payload.institutionName,
                theme: action.payload.theme,
            };
        },
        updateDocId: (state, action) => {
            state.info.docId = action.payload;
        },
        updateDocName: (state, action) => {
            state.info.name = action.payload;
        },
        updateInstitutionName: (state, action) => {
            state.info.institutionName = action.payload;
        },

        updateCertificationStatus: (state, action) => {
            state.exam.certifiable = action.payload;
        },
        updateDifficultyLevel: (state, action) => {
            state.exam.difficultyLevel = action.payload;
        },

        updateTimeStatus: (state, action) => {
            state.timer.isTimerAvailable = action.payload;
        },
        updateTimeEndStatus: (state, action) => {
            state.timer.isTimeEnd = action.payload;
        },
        updateCounterTimeStatus: (state, action) => {
            state.timer.counter = action.payload;
        },
        updateDocTime: (state, action) => {
            state.timer.timeInSeconds = action.payload;
        },


        updateNoteSystem: (state, action) => {
            state.graduation.notationType = action.payload;
        },

        updateSharedStatus: (state, action) => {
            state.details.shared = action.payload;
        }
    }
});


export const { resetConfigSlice, saveConfiguration, updateDocId, updateDocName, updateInstitutionName,
    updateDifficultyLevel, updateTimeStatus, updateTimeEndStatus, updateDocTime, updateNoteSystem,
    updateCounterTimeStatus, updateSharedStatus, updateCertificationStatus,
    saveInfoConfiguration} = configSlice.actions;

export const selectNuggetInfos = (state) => state.config.info;
export const selectWorkspaceId = (state) => state.config.info.workspaceId;
export const selectModuleId = (state) => state.config.info.moduleId;
export const selectNuggetId = (state) => state.config.info.nuggetId;
export const selectDocName = (state) => state.config.info.name;
export const selectInstitutionName = (state) => state.config.info.institutionName;
export const selectDocTheme = (state) => state.config.info.theme;

export const selectIsTimerAvailable = (state) => state.config.timer.isTimerAvailable;
export const selectTimeEnd = (state) => state.config.timer.isTimeEnd;
export const selectCounterTime = (state) => state.config.timer.counter;
export const selectDocTime = (state) => state.config.timer.timeInSeconds;

export const selectMaxScore = (state) => state.config.graduation.maxScore;
export const selectNoteSystem = (state) => state.config.graduation.notationType;

export const selectCertificationStatus = (state) => state.config.exam.certifiable;
export const selectCertificateCompanyLogo = (state) => state.config.exam.companyLogo;
export const selectCertificateDescription = (state) => state.config.exam.description;
export const selectMinCertificationPercentage = (state) => state.config.exam.certificateMinimumPercent;
export const selectDifficultyLevel = (state) => state.config.exam.difficultyLevel;

export const selectSharedStatus = (state) => state.config.details.shared;
export const selectSocialMediaFeaturesStatus = (state) => state.config.details.socialMediaFeatures;
export const selectDocPassword = (state) => state.config.details.password;



export default configSlice.reducer;
