// Editor Card Theme
export const QUIZ_DEFAULT_THEME = {
    bgCard: 'bg-card-quiz-default'
};

export const NOTES_DEFAULT_THEME = {
    bgCard: 'bg-card-notes-default'
};

export const VIDEO_DEFAULT_THEME = {
    bgCard: 'bg-card-video-default'
};

export const AUDIO_DEFAULT_THEME = {
    bgCard: 'bg-card-audio-default'
};

export const LINK_DEFAULT_THEME = {
    bgCard: 'bg-card-link-default'
};

export const IMAGE_DEFAULT_THEME = {
    bgCard: 'bg-card-image-default'
};

export const STORY_END_THEME = {
    bgCard: 'bg-card-story-end',
}


// Display Card Workspace Page
export const WORKSPACE_CARD_THEME_1 = {
    bgColor: 'bg-card-module-color-1',
    bgImage: 'bg-card-workspace-image-1'
};


// Display Card Module Page
export const MODULE_CARD_THEME_1 = {
    bgColor: 'bg-card-module-color-1',
    bgImage: 'bg-card-module-image-1'
};


// Display Card Nugget's Page
export const COVER_DEFAULT_THEME = {
    bgColor: 'bg-card-doc-color-default',
    bgImage: 'bg-card-doc-image-default'
};

export const DOC_CARD_THEME_1 = {
    bgColor: 'bg-card-doc-color-1',
    bgImage: 'bg-card-doc-image-1'
};

export const DOC_CARD_THEME_2 = {
    bgColor: 'bg-card-doc-color-2',
    bgImage: 'bg-card-doc-image-2'
};

export const DOC_CARD_THEME_3 = {
    bgColor: 'bg-card-doc-color-3',
    bgImage: 'bg-card-doc-image-3'
};

export const DOC_CARD_THEME_4 = {
    bgColor: 'bg-card-doc-color-4',
    bgImage: 'bg-card-doc-image-4'
};

export const DOC_CARD_THEME_5 = {
    bgColor: 'bg-card-doc-color-5',
    bgImage: 'bg-card-doc-image-5'
};

export const DOC_CARD_THEME_6 = {
    bgColor: 'bg-card-doc-color-6',
    bgImage: 'bg-card-doc-image-6'
};

export const DOC_CARD_THEME_7 = {
    bgColor: 'bg-card-doc-color-7',
    bgImage: 'bg-card-doc-image-7'
};


export const DEFAULT_PRIMARY_COLOR = '#AB94F4';
export const DEFAULT_SECONDARY_COLOR = '#FFE564';
export const DEFAULT_CERTIFICATE_COLOR = '#423A79';
export const DEFAULT_STORY_INFO_COLOR = '#37365D';
