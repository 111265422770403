import React from "react";
import {Navigate} from "react-router-dom";

import {useQuery} from "../../pages/shared/hooks/router-hook";


const TemporaryRoute = ({children}) => {

    const queryParams = useQuery();
    const nonce = queryParams.get("nonce");


    if(!nonce) {
        return <Navigate to="/login" replace={true} />;
    }


    return children;
}

export default React.memo(TemporaryRoute);
