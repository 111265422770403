import React, {Suspense} from "react";
import {Routes, Route} from "react-router-dom";
import {useSelector} from "react-redux";

import PreLoading from "../features/loading/PreLoading";
import {
    ACCOUNT_PAGE, ADMIN_PAGE, DOCUMENT_PAGE, EDITOR_PAGE, LOGIN_PAGE, PASSWORD_RECOVERY_PAGE,
    PLAN_CHECKOUT_SUCCESS, PLAN_PAGE, RESET_PASSWORD_PAGE, STORY_PAGE, TEMPLATE_GENERATION_PAGE,
    TEMPLATE_SELECTION_PAGE, WORKSPACE_PAGE
} from "../config/lazy-loading";
import {selectLoginStatus} from "../store/reducer/user/userSlice";
import ProtectedRoute from "./elements/ProtectedRoute";
import OpenRoute from "./elements/OpenRoute";
import TemporaryRoute from "./elements/TemporaryRoute";
import RedirectRoute from "./elements/RedirectRoute";


const AppRouter = () => {

    const isAuthenticated = useSelector(selectLoginStatus);


    return (
        <Routes>
            <Route path="/">
                <Route exact index
                       element={
                           <Suspense fallback={<PreLoading />}>
                               <RedirectRoute />
                           </Suspense>
                       }
                />

                <Route path="login"
                       element={
                           <Suspense fallback={<PreLoading />}>
                               <OpenRoute isAuthenticated={isAuthenticated}>
                                   <LOGIN_PAGE />
                               </OpenRoute>
                           </Suspense>
                       }
                />

                <Route path="account"
                       element={
                           <Suspense fallback={<PreLoading />}>
                               <ProtectedRoute isAuthenticated={isAuthenticated}>
                                   <ACCOUNT_PAGE />
                               </ProtectedRoute>
                           </Suspense>
                       }
                />

                <Route path="plan"
                       element={
                           <Suspense fallback={<PreLoading />}>
                               <ProtectedRoute isAuthenticated={isAuthenticated}>
                                   <PLAN_PAGE />
                               </ProtectedRoute>
                           </Suspense>
                       }
                />

                <Route exact path="template-generation"
                       element={
                           <Suspense fallback={<PreLoading />}>
                               <ProtectedRoute isAuthenticated={isAuthenticated}>
                                   <TEMPLATE_GENERATION_PAGE />
                               </ProtectedRoute>
                           </Suspense>
                       }
                />

                <Route exact path="template-selection"
                       element={
                           <Suspense fallback={<PreLoading />}>
                               <ProtectedRoute isAuthenticated={isAuthenticated}>
                                   <TEMPLATE_SELECTION_PAGE />
                               </ProtectedRoute>
                           </Suspense>
                       }
                />


                <Route exact path="auth/change-password"
                       element={
                           <Suspense fallback={<PreLoading />}>
                               <TemporaryRoute>
                                   <RESET_PASSWORD_PAGE />
                               </TemporaryRoute>
                           </Suspense>
                       }
                />

                <Route exact path="auth/password-recovery"
                       element={
                           <Suspense fallback={<PreLoading />}>
                               <OpenRoute isAuthenticated={isAuthenticated}>
                                   <PASSWORD_RECOVERY_PAGE />
                               </OpenRoute>
                           </Suspense>
                       }
                />


                <Route exact path="checkout/success"
                       element={
                           <Suspense fallback={<PreLoading />}>
                               <ProtectedRoute isAuthenticated={isAuthenticated}>
                                   <PLAN_CHECKOUT_SUCCESS />
                               </ProtectedRoute>
                           </Suspense>
                       }
                />


                <Route path="admin">
                    <Route index
                           element={
                               <Suspense fallback={<PreLoading />}>
                                   <ProtectedRoute isAuthenticated={isAuthenticated} >
                                       <ADMIN_PAGE />
                                   </ProtectedRoute>
                               </Suspense>
                           }
                    />
                </Route>


                <Route path="workspaces">
                    <Route index
                           element={
                               <Suspense fallback={<PreLoading />}>
                                   <ProtectedRoute isAuthenticated={isAuthenticated}>
                                       <WORKSPACE_PAGE />
                                   </ProtectedRoute>
                               </Suspense>
                           }
                    />
                </Route>

                <Route path="workspaces/:workspaceId/mods/:moduleId/nuggets">
                    <Route index
                           element={
                               <Suspense fallback={<PreLoading />}>
                                   <ProtectedRoute isAuthenticated={isAuthenticated}>
                                       <DOCUMENT_PAGE />
                                   </ProtectedRoute>
                               </Suspense>
                           }
                    />

                    <Route path="create"
                           element={
                               <Suspense fallback={<PreLoading />}>
                                   <ProtectedRoute isAuthenticated={isAuthenticated}>
                                       <EDITOR_PAGE />
                                   </ProtectedRoute>
                               </Suspense>
                           }
                    />

                    <Route path=":nuggetId/edit"
                           element={
                               <Suspense fallback={<PreLoading />}>
                                   <ProtectedRoute isAuthenticated={isAuthenticated}>
                                       <EDITOR_PAGE />
                                   </ProtectedRoute>
                               </Suspense>
                           }
                    />

                    <Route path=":nuggetId/story"
                           element={
                               <Suspense fallback={<PreLoading neutral={true} />}>
                                   <STORY_PAGE />
                               </Suspense>
                           }
                    />
                </Route>


                <Route path="story"
                       element={
                           <Suspense fallback={<PreLoading neutral={true} />}>
                               <RedirectRoute />
                           </Suspense>
                       }
                />


                <Route path="*"
                       element={
                           <Suspense fallback={<PreLoading />}>
                               <RedirectRoute />
                           </Suspense>
                       }
                />
            </Route>
        </Routes>
    );
}

export default React.memo(AppRouter);
