import { createSlice } from "@reduxjs/toolkit";

import {STORY_INITIAL_STATE} from "../../state/storyDefaultState";


export const storySlice = createSlice({
    name: "story",
    initialState: STORY_INITIAL_STATE,
    reducers: {
        resetStorySlice: () => STORY_INITIAL_STATE,
        saveStoryItems: (state, action) => {
            state.storyArray = action.payload;
        },
        saveStoryType: (state, action) => {
            state.storyType = action.payload;
        },
        saveCanGoNextStatus: (state, action) => {
            state.storyArray[action.payload.slideIndex].canGoNext = action.payload.canGoNext;
        },
        saveCanShake: (state, action) => {
            state.storyArray[action.payload.slideIndex].canShake = action.payload.canShake;
        },
        saveShouldCheckNextStatus: (state, action) => {
            state.shouldCheckNext = action.payload;
        },
        addChosenAnswerMC: (state, action) => {
            const slideIndex = action.payload.slideIndex;
            const distractorIndex = action.payload.distractorIndex;
            state.storyArray[slideIndex].answerIndexesChoose.push(distractorIndex);
            state.storyArray[slideIndex].distractors[distractorIndex].answer = true;
        },
        removeChosenAnswerMC: (state, action) => {
            const slideIndex = action.payload.slideIndex;
            const distractorIndex = action.payload.distractorIndex;
            state.storyArray[slideIndex].answerIndexesChoose = state.storyArray[slideIndex].answerIndexesChoose.filter(
                item => item !== distractorIndex
            );
            state.storyArray[slideIndex].distractors[distractorIndex].answer = false;
        },
        updateStoryItem: (state, action) => {
            const key = action.payload.key;
            const index = action.payload.slideIndex;
            state.storyArray[index][key] = action.payload.value;
        },
        updateSwipeDirection: (state, action) => {
            state.swipeDirection = action.payload;
        },
        updateShouldSaveCardProgress: (state, action) => {
            state.shouldSaveCardProgression = action.payload;
        },
    },
});

export const { saveStoryItems, saveCanGoNextStatus, saveShouldCheckNextStatus, saveStoryType, updateSwipeDirection,
    addChosenAnswerMC, removeChosenAnswerMC, resetStorySlice, saveCanShake, updateStoryItem,
    updateShouldSaveCardProgress} = storySlice.actions;


export const selectStoryArray = (state) => state.story.storyArray;
export const selectStorySize = state => state.story.storyArray.length;
export const selectStoryType = state => state.story.storyType;
export const selectSwipeDirection = state => state.story.swipeDirection;

export const selectShouldCheckNext = state => state.story.shouldCheckNext;
export const selectShouldSaveCardProgression = state => state.story.shouldSaveCardProgression;


export default storySlice.reducer;
