
export const DELETE_MESSAGE = "Are you sure want to delete this content?";
export const DELETE_FAILED_MESSAGE = "Delete failed - Please try again later";

export const NUGGET_MAIN_VIEW = 'main-view';
export const SHARE_VIEW = 'share-view';
export const NUGGET_SETTINGS_VIEW = 'settings-view';
export const NUGGET_STATS_VIEW = 'stats-view';

export const PAGINATOR_OFFSET = 5;

export const SETTINGS_SUCCESS_ALERT = 'Your modifications has been saved';
export const SETTINGS_ERROR_ALERT = 'An error occurred, please try again later';

export const STATS_NOT_FOUND_ALERT = 'There are no statistics for this nugget at the moment';

export const KNOWLEDGE_DIMENSION_FACT = 'FACT';
export const KNOWLEDGE_DIMENSION_CONCEPT = 'CONCEPT';
export const KNOWLEDGE_DIMENSION_PROCEDURE = 'PROCEDURE';

export const COGNITIVE_LEVEL_REMEMBER = 'REMEMBER';
export const COGNITIVE_LEVEL_UNDERSTAND = 'UNDERSTAND';
export const COGNITIVE_LEVEL_APPLY = 'APPLY';
export const COGNITIVE_LEVEL_ANALYSE = 'ANALYSE';
export const COGNITIVE_LEVEL_EVALUATE = 'EVALUATE';
