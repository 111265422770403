import { configureStore } from "@reduxjs/toolkit";

import contentReducer from "../../app/store/reducer/contentSlice";
import learningUnitReducer from "./reducer/learningUnitSlice";
import documentReducer from "./reducer/home/documentSlice";
import moduleReducer from "./reducer/home/moduleSlice";
import userReducer from "./reducer/user/userSlice";
import adminReducer from "./reducer/admin/adminSlice";
import saveReducer from "./reducer/editor/saveSlice";
import uiReducer from "./reducer/common/uiSlice";
import storyReducer from "./reducer/story/storySlice";
import generationReducer from "./reducer/generateSlice";
import loadingReducer from "./reducer/common/loadingSlice";
import configReducer from "./reducer/common/configSlice";
import certificateReducer from "./reducer/story/certificateSlice";
import editorReducer from "./reducer/editor/editorSlice";
import workspaceReducer from "./reducer/home/workspaceSlice";


export default configureStore({
  reducer: {
    user: userReducer,
    admin: adminReducer,
    content: contentReducer,
    learningUnit: learningUnitReducer,
    workspace: workspaceReducer,
    module: moduleReducer,
    document: documentReducer,
    save: saveReducer,
    ui: uiReducer,
    story: storyReducer,
    generation: generationReducer,
    loading: loadingReducer,
    config: configReducer,
    certificate: certificateReducer,
    editor: editorReducer,
  },
});
