import { createSlice } from "@reduxjs/toolkit";

export const learningUnitSlice = createSlice({
    name: "learningUnit",
    initialState: {
        textSummary: null,
        htmlSummary: null,
        highlighted: false,
        percentage: null,
        readTime: null,
        keywords: null,
        userSetPercentage: null,
        isSummaryLoading: false,
    },
    reducers: {
        saveSummary: (state, action) => {
            state.textSummary = action.payload.textSummary;
            state.htmlSummary = action.payload.htmlSummary;
            state.highlighted = action.payload.highlighted;
        },
        updateHighlightStatus: (state, action) => {
            state.highlighted = action.payload;
        },
        saveSummaryMetrics: (state, action) => {
            state.percentage = action.payload.percentage;
            state.readTime = action.payload.readTime;
            state.keywords = action.payload.keywords;
        },
        saveUserSetPercentage: (state, action) => {
            state.userSetPercentage = action.payload;
        },
        updateIsSummaryLoading: (state, action) => {
            state.isSummaryLoading = action.payload;
        },
    },
});

export const { saveSummary, saveSummaryMetrics, saveUserSetPercentage,
    updateIsSummaryLoading, updateHighlightStatus} = learningUnitSlice.actions;


export const selectHtmlSummary = (state) => state.learningUnit.htmlSummary;
export const selectTextSummary = (state) => state.learningUnit.textSummary;
export const selectHighlightStatus = (state) => state.learningUnit.highlighted;

export const selectSummaryReadTime = (state) => state.learningUnit.readTime;
export const selectSummaryPercentage = (state) => state.learningUnit.percentage;
export const selectSummaryKeywords = (state) => state.learningUnit.keywords;
export const selectUserSetPercentage = (state) => state.learningUnit.userSetPercentage;

export const selectIsSummaryLoading = (state) => state.learningUnit.isSummaryLoading;

export default learningUnitSlice.reducer;
