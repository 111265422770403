import React, {useEffect, useState} from "react";
import {ThemeProvider, StyledEngineProvider} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import * as Sentry from "@sentry/browser";
import {IntlProvider} from "react-intl";
import {isEmpty} from "lodash";

import {LANGUAGE_MESSAGES} from "./app/pages/shared/utils/constants/shared-constants";
import {selectChosenLanguage, selectShouldUpdateUserInfo, updateShowNameDialog, updateUserInfo
} from "./app/store/reducer/common/uiSlice";
import {APP_THEME} from "./app/config/theme";
import PreLoading from "./app/features/loading/PreLoading";
import {saveCounters, saveUserConfiguration, selectLoginStatus} from "./app/store/reducer/user/userSlice";
import {getCurrentLoggedUser, logout} from "./app/service/auth/sessionService";
import {fetchUserInfo} from "./app/service/auth/userService";
import {updateCandidateEmailAddress, updateCandidateFullName} from "./app/store/reducer/story/certificateSlice";
import AppRouter from "./app/router/AppRouter";

import "./App.scss";
import "./theme/theme.scss";


function App() {

    const dispatch = useDispatch();

    const isAuthenticated = useSelector(selectLoginStatus);

    // language without region code
    const chosenLanguage = useSelector(selectChosenLanguage);
    const shouldUpdateUserInfo = useSelector(selectShouldUpdateUserInfo);

    const [loading, setLoading] = useState(false);


    useEffect(() => {
        if ((isAuthenticated && getCurrentLoggedUser()) || shouldUpdateUserInfo) {
            setLoading(true);
            fetchUserInfo()
                .then((response) => {
                    const userInfo = response.data;
                    Sentry.setUser({ email: userInfo.emailAddress });

                    dispatch(updateCandidateEmailAddress(userInfo.emailAddress));

                    dispatch(saveUserConfiguration(userInfo));
                    dispatch(saveCounters(userInfo));

                    dispatch(updateUserInfo({ status: false }));
                    setLoading(false);

                    if(isEmpty(userInfo.name)) {
                        dispatch(updateShowNameDialog(true));
                    } else {
                        dispatch(updateCandidateFullName( userInfo.name));
                    }
                })
                .catch((error) => {
                    setLoading(false);
                    dispatch(updateUserInfo({ status: false }));
                    if (error?.response?.status === 401) {
                        logout();
                    }
                });
        }
    }, [isAuthenticated, shouldUpdateUserInfo]);


    if(loading) {
        return <PreLoading />;
    }


    return (
        <IntlProvider messages={LANGUAGE_MESSAGES[chosenLanguage]} locale={chosenLanguage} defaultLocale="en">
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={APP_THEME}>
                    <AppRouter />
                </ThemeProvider>
            </StyledEngineProvider>
        </IntlProvider>
    );
}

export default App;
