import {NUGGET_MAIN_VIEW} from "../../pages/home/nuggets/utils/constants/doc-constants";
import {WORKSPACE_MAIN_VIEW} from "../../pages/home/workspace/utils/constants/workspace-constants";


export const WORKSPACE_INITIAL_STATE = {
    allWorkspaces: [],
    activeWorkspace: null,
    editableWorkspace: null,
    cardView: WORKSPACE_MAIN_VIEW,
    defaultWorkspace: null,
}


export const MODULE_INITIAL_STATE = {
    allModules: [],
    activeModule: null,
    defaultModuleId: null,
    totalNumberOfPages: null,
    totalNumberOfModules: null,
};


export const DOCUMENT_INITIAL_STATE = {
    allDocs: [],
    cardView: NUGGET_MAIN_VIEW,
    activeDocument: null,
    shouldRefresh: true,
    statsViewInfos: {
        refreshStats: true,
        loadStats: true,
        isError: false
    }
};

const editableWorkspace = {
    "ownersEmailAddresses": [
        "temgouaberenger@yahoo.fr"
    ],
    "authorsEmailAddresses": [],
    "publishersEmailAddresses": [],
    "features": {},
    "name": "Hitech4Every1",
    "primaryColor": null,
    "secondaryColor": null,
    "background": null,
    "logo": null,
    "customCovers": false,
    "attachLogoOnFirstCard": false,
    "numOfPrivateParticipants": 200,
    "numOfAuthors": 3,
    "advancedFeatures": false
}
