
export const DEFAULT_WORKSPACE_ID = 'private';

export const WORKSPACE_MAIN_VIEW = 'WORKSPACE_MAIN_VIEW';
export const WORKSPACE_CONFIG_VIEW = 'WORKSPACE_CONFIG_VIEW';
export const WORKSPACE_BILLING_VIEW = 'WORKSPACE_BILLING_VIEW';
export const WORKSPACE_USER_INVITATION_VIEW = 'WORKSPACE_USER_INVITATION_VIEW';
export const WORKSPACE_USER_MANAGEMENT_VIEW = 'WORKSPACE_USER_MANAGEMENT_VIEW';

export const WORKSPACE_ROLE_OWNER = 'OWNER';
export const WORKSPACE_ROLE_AUTHOR = 'AUTHOR';
export const WORKSPACE_ROLE_PUBLISHER = 'PUBLISHER';

export const WORKSPACE_BG_TYPE_IMAGE = 'WORKSPACE_BG_TYPE_IMAGE';
export const WORKSPACE_BG_FILE_TYPES = ["image/jpeg", "image/png, image/svg+xml"];

export const WORKSPACE_BG_TYPE_COLOR = 'WORKSPACE_BG_TYPE_COLOR';

export const WORKSPACE_BG_COLOR_PRIMARY = 'WORKSPACE_BG_COLOR_PRIMARY';
export const WORKSPACE_BG_COLOR_SECONDARY = 'WORKSPACE_BG_COLOR_SECONDARY';
