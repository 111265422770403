
export const ERROR_MESSAGE_403_FREE_QUIZ = 'Upgrade to {task} {request}';
export const ERROR_MESSAGE_403_PLAN_QUIZ = 'You have reached your limit for this month. Upgrade to {task} more {request}';

export const ERROR_MESSAGE_401 = 'Please reload and log in.';

export const ERROR_MESSAGE_413 = 'Your file is too large. ' +
    'Please upload a file with size not greater than your plan\'s max size';

export const ERROR_MESSAGE_LANGUAGE_NOT_SUPPORTED = 'Flexudy can only summarise text in German, English, Dutch and French';
export const ERROR_MESSAGE_LANGUAGE_NOT_SUPPORTED_QUESTION = 'Flexudy can only generate question in German, English, Dutch and French';

export const ERROR_MESSAGE_FILE_TOO_LARGE = 'Your file is too large. Please upload a file with size not ' +
    'greater than your plan\'s max size.';

export const ERROR_MESSAGE_PLAN_UNSUPPORTED_FILE_TYPE = 'Your current plan does not support the uploaded file type';

export const ERROR_MESSAGE_DATA_SOURCE_NOT_PRESENT = 'Your content or link is unreadable';

export const ERROR_MESSAGE_500 = 'An error occurred. Please try again later';

export const STATUS_CODE_403 = 403;


export const KEY_LOCAL_STORAGE_USER = 'Flexudy-Exodus';
export const KEY_LOCAL_STORAGE_GUEST_ID = 'flexudyGuestId';
export const KEY_LOCAL_STORAGE_INTRO_VIDEO = 'Flexudy-intro-video';
export const KEY_LOCAL_EDITOR_GUIDE = 'Flexudy-editor-guide';
export const KEY_LOCAL_TRAINING_GUIDE = 'Flexudy-training-guide';
export const KEY_LOCAL_CERTIFICATION_GUIDE = 'Flexudy-certification-guide';

export const KEY_LOCAL_USER_LANGUAGE = 'Flexudy-user-language'
