import {createTheme} from "@mui/material";

export const APP_THEME = createTheme(
    {
        palette: {
            primary: {
                main: "#544A92",
            },
            secondary: {
                main: "#423A79",
            },
            error: {
                main: "#E86A6A",
            },
        },
        typography: {
            fontSize: 14,
            fontFamily: "Open Sans, sans-serif",
        },
    }
)
