import messages_de from "../../../../../lang/de.json";
import messages_en from "../../../../../lang/en.json";
import messages_fr from "../../../../../lang/fr.json";
import messages_nl from "../../../../../lang/nl.json";

const LANDING_ASSETS_BASE_URL = `${process.env.REACT_APP_CDN_STATIC_CONTENT_BUCKET}/landing`;

export const logo = `${LANDING_ASSETS_BASE_URL}/flexudy-logo.png`;
export const logoPurpleSVG = `${LANDING_ASSETS_BASE_URL}/flexudy-logo-purple.svg`;
export const logoWhiteSVG = `${LANDING_ASSETS_BASE_URL}/flexudy-logo-white.svg`;
export const miniLogoSVG = `${LANDING_ASSETS_BASE_URL}/flexudy_mini_logo.svg`;
export const miniLogoWhiteSVG = `${LANDING_ASSETS_BASE_URL}/mini_logo_white.svg`;

export const STORY_TYPE_QUIZ = 'quiz';
export const STORY_TYPE_BADGE = 'badge';
export const STORY_TYPE_INFO = 'info';
export const STORY_TYPE_ERROR = 'error';

export const VIEW_TYPE_QUESTION_MULTIPLE = 'question-multiple';
export const VIEW_TYPE_NOTES = 'notes';
export const VIEW_TYPE_YOUTUBE = 'youtube';
export const VIEW_TYPE_AUDIO = 'audio';
export const VIEW_TYPE_VIDEO = 'video';
export const VIEW_TYPE_LINK = 'link';
export const VIEW_TYPE_IMAGE = 'image';
export const VIEW_TYPE_COVER = 'cover';
export const VIEW_TYPE_STORY_END = 'end-view';

export const REGULAR_POINT_SYSTEM = 'REGULAR_POINT_SYSTEM';
export const GRADE_SCALE = 'GRADE_SCALE';
export const LETTER_GRADE = 'LETTER_GRADE';
export const IHK_NOTENSCHLUESSEL = 'IHK_NOTENSCHLUESSEL';
export const PERCENTAGE_GRADE = 'PERCENTAGE_GRADE';
export const HIGHER_EDUCATION_GERMANY = 'HIGHER_EDUCATION_GERMANY';
export const PROTECTED_GRADING_SYSTEM = [GRADE_SCALE, LETTER_GRADE, IHK_NOTENSCHLUESSEL, HIGHER_EDUCATION_GERMANY];

export const EXIT_MESSAGE = "Do you want to save before you continue?";

export const QUIZ_LEVEL_1 = 'LEVEL_1';
export const QUIZ_LEVEL_2 = 'LEVEL_2';
export const QUIZ_LEVEL_3 = 'LEVEL_3';
export const QUIZ_LEVEL_4 = 'LEVEL_4';
export const QUIZ_LEVEL_5 = 'LEVEL_5';

export const PERCENTAGE_MINIMUM_FOR_CERTIFICATION = 0.7;
export const DEFAULT_QUIZ_TIME_SECONDS = '120';

export const LANGUAGE_ENGLISH = 'en';
export const LANGUAGE_GERMAN = 'de';
export const LANGUAGE_FRENCH = 'fr';
export const LANGUAGE_DUTCH = 'nl';

export const LANGUAGE_MESSAGES = {
    'de': messages_de,
    'en': messages_en,
    'fr': messages_fr,
    'nl': messages_nl,
};


export const DOWNLOAD_TYPE_ILIAS = 'ILIAS';
export const DOWNLOAD_TYPE_MOODLE = 'MOODLE';
export const DOWNLOAD_TYPE_PDF = 'PDF';
export const DOWNLOAD_TYPE_WORD = 'WORD';
export const DOWNLOAD_TYPE_KAHOOT = 'KAHOOT';
export const DOWNLOAD_TYPE_QUIZLET = 'QUIZLET';

export const PLAN_FREE = 'FREE';
export const PLAN_BASIC = 'BASIC';
export const PLAN_PRO = 'PROFESSIONAL';
export const PLAN_ENTERPRISE = 'ENTERPRISE';

export const PRICING_PLAN_LEVEL_1 = 1;
export const PRICING_PLAN_LEVEL_2 = 2;
export const PRICING_PLAN_LEVEL_3 = 3;
export const PRICING_PLAN_LEVEL_4 = 4;

export const COGNITIVE_LEVEL_REMEMBER = 'REMEMBER';
export const COGNITIVE_LEVEL_UNDERSTAND = 'UNDERSTAND';
export const COGNITIVE_LEVEL_APPLY = 'APPLY';
export const COGNITIVE_LEVEL_ANALYSE = 'ANALYSE';
export const COGNITIVE_LEVEL_EVALUATE = 'EVALUATE';

export const KNOWLEDGE_LEVEL_FACT = 'FACT';
export const KNOWLEDGE_LEVEL_CONCEPT = 'CONCEPT';
export const KNOWLEDGE_LEVEL_PROCEDURE = 'PROCEDURE';

export const PUBLIC_AVAILABLE = 'PUBLIC_AVAILABLE';
export const UNLISTED = 'UNLISTED';
export const PASSWORD_PROTECTED = 'PASSWORD_PROTECTED';

