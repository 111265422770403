import { lazy } from "react";


export const PLAN_CHECKOUT_SUCCESS = lazy(() =>
  import("../pages/billing/components/confirmation/PlanCheckoutSuccess")
);

export const LOGIN_PAGE = lazy(() => import("../pages/login/LoginPage"));

export const RESET_PASSWORD_PAGE = lazy(() => import("../pages/auth/reset-password/ResetPasswordPage"));

export const PASSWORD_RECOVERY_PAGE = lazy(() => import("../pages/auth/password-recovery/PasswordRecoveryPage"));

export const WORKSPACE_PAGE = lazy(() => import("../pages/home/workspace/WorkspacePage"));
export const WORKSPACE_SETTINGS_PAGE = lazy(() => import("../pages/home/workspace/WorkspacePage"));
export const WORKSPACE_BILLING_PAGE = lazy(() => import("../pages/home/workspace/WorkspacePage"));
export const WORKSPACE_USER_INVITATION_PAGE = lazy(() => import("../pages/home/workspace/WorkspacePage"));
export const WORKSPACE_USER_MANAGEMENT_PAGE = lazy(() => import("../pages/home/workspace/WorkspacePage"));

export const ADMIN_PAGE = lazy(() => import("../pages/admin/AdminPage"));

export const ACCOUNT_PAGE = lazy(() => import("../pages/account/AccountPage"));

export const PLAN_PAGE = lazy(() => import("../pages/plan/PlanPage"));

export const TEMPLATE_GENERATION_PAGE = lazy(() => import("../pages/template-generation/TemplateGenerationPage"));

export const TEMPLATE_SELECTION_PAGE = lazy(() => import("../pages/template-selection/TemplateSelectionPage"));

export const DOCUMENT_PAGE = lazy(() => import("../pages/home/nuggets/NuggetsPage"));

export const EDITOR_PAGE = lazy(() => import("../pages/editor/EditorPage"));

export const STORY_PAGE = lazy(() => import("../pages/story/StoryPage"));

export const LOADING_PAGE = lazy(() => import("../features/loading/SuspenseLoading"));

export const ERROR_PAGE = lazy(() => import("../pages/error/ErrorPage"));
