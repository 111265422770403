import {getCurrentLoggedUser} from "../../service/auth/sessionService";


export const USER_INITIAL_STATE = {
    contentChecksum: null,
    authInfos: {
        name: null,
        emailAddress: null,
        licenseKey: null,
        token: null,
        loginStatus: !!getCurrentLoggedUser(),
        flexudyAdmin: false,
        tenants: [],
        defaultModule: null,
    },
    subscriptionInfos: {
        subscription: {
            active: false,
            plan: '',
            lastDayOfAccess: '',
            flexudyManaged: false,
            numberOfDaysLeft: 12
        },
        whitelistedUser: false,
        currentlyOnDemoPlan: false
    },
    features: {
        maxNumberOfCharacters: 300,
    },
    mediaSize: {
        fileStorage: {
            usedSizeInMB: 0,
            maximumAllowedSizeInMB: 0,
        },
        aiGeneration: {
            currentMonthlyAIGenerations: 0,
            maxMonthlyAIGenerations: 0
        }
    },
    story: {
        isAnonymous: !getCurrentLoggedUser()
    }
}
