import {
    VIEW_TYPE_AUDIO, VIEW_TYPE_IMAGE, VIEW_TYPE_LINK,
    VIEW_TYPE_NOTES, VIEW_TYPE_QUESTION_MULTIPLE, VIEW_TYPE_VIDEO
} from "../../../shared/utils/constants/shared-constants";


export const buildViewCardURL = (nuggetsInfos, cardId, viewType) => {
    let url;
    switch (viewType) {
        case VIEW_TYPE_NOTES:
            url = `api/internal/modules/${nuggetsInfos.moduleId}/quizzes/${nuggetsInfos.nuggetId}/notes/${cardId}/views`;
            break;

        case VIEW_TYPE_LINK:
            url = `api/internal/modules/${nuggetsInfos.moduleId}/quizzes/${nuggetsInfos.nuggetId}/links/${cardId}/views`;
            break;

        case VIEW_TYPE_VIDEO:
            url = `api/internal/modules/${nuggetsInfos.moduleId}/quizzes/${nuggetsInfos.nuggetId}/youtubeLinks/${cardId}/views`;
            break;

        case VIEW_TYPE_QUESTION_MULTIPLE:
            url = `api/internal/modules/${nuggetsInfos.moduleId}/quizzes/${nuggetsInfos.nuggetId}/questions/${cardId}/views`;
            break;

        case VIEW_TYPE_IMAGE:
            url = `api/internal/modules/${nuggetsInfos.moduleId}/quizzes/${nuggetsInfos.nuggetId}/images/${cardId}/views`;
            break;

        case VIEW_TYPE_AUDIO:
            url = `api/internal/modules/${nuggetsInfos.moduleId}/quizzes/${nuggetsInfos.nuggetId}/audios/${cardId}/views`;
            break;
    }

    return url;
}


export const buildLikeCardURL = (nuggetsInfos, cardId, viewType) => {
    let url;
    switch (viewType) {
        case VIEW_TYPE_NOTES:
            url = `api/internal/modules/${nuggetsInfos.moduleId}/quizzes/${nuggetsInfos.nuggetId}/notes/${cardId}/likes`;
            break;

        case VIEW_TYPE_LINK:
            url = `api/internal/modules/${nuggetsInfos.moduleId}/quizzes/${nuggetsInfos.nuggetId}/links/${cardId}/likes`;
            break;

        case VIEW_TYPE_VIDEO:
            url = `api/internal/modules/${nuggetsInfos.moduleId}/quizzes/${nuggetsInfos.nuggetId}/youtubeLinks/${cardId}/likes`;
            break;

        case VIEW_TYPE_QUESTION_MULTIPLE:
            url = `api/internal/modules/${nuggetsInfos.moduleId}/quizzes/${nuggetsInfos.nuggetId}/questions/${cardId}/likes`;
            break;

        case VIEW_TYPE_IMAGE:
            url = `api/internal/modules/${nuggetsInfos.moduleId}/quizzes/${nuggetsInfos.nuggetId}/images/${cardId}/likes`;
            break;

        case VIEW_TYPE_AUDIO:
            url = `api/internal/modules/${nuggetsInfos.moduleId}/quizzes/${nuggetsInfos.nuggetId}/audios/${cardId}/likes`;
            break;
    }

    return url;
}
