import axios from "axios";

import {storeGuestId, getCurrentGuestUser, getCurrentLoggedUser} from "./auth/sessionService";


const API_HEADER = {
    "userType": "EDUCATION",
    "Content-Type": "application/json",
};


export const httpClient = axios.create({
    baseURL: process.env.REACT_APP_GATEWAY_API_URL,
    headers: API_HEADER,
});


httpClient.interceptors.request.use(function (request) {
    const flexudyGuestId = getCurrentGuestUser();
    const loggedInUser = getCurrentLoggedUser();


    if (loggedInUser) {
        const storedLicenseKey = loggedInUser.licenseKey;
        if (storedLicenseKey) {
            request.headers.licenseKey = storedLicenseKey;
        }
        request.headers.Authorization = loggedInUser.token;
        return request;
    }

    if (flexudyGuestId) {
        request.headers.flexudyGuestId = flexudyGuestId;
    }
    return request;
});


httpClient.interceptors.response.use(
    function (response) {
        const flexudyGuestId = response.headers["flexudyguestid"];
        if (flexudyGuestId) {
            storeGuestId(flexudyGuestId);
        }
        return response;
    },
    function (error) {
        const requestedUrl = error.response.config.url;
        const status = error.response.status;
        if(status === 401 && requestedUrl !== '/api/v1/b2b/login/exodus') {
            window.location = '/login'
        }
        return Promise.reject(error);
    }
);


export default httpClient;
